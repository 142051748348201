import { useRouter } from "next/router";
import React from "react";

import { Layout, Image, Col, Row, Card } from "antd";
import LoginForm from "./LoginForm";

const { Content, Footer } = Layout;

export default function () {
  const router = useRouter();

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Content>
        <Row align="top" justify="center">
          <Col xs={0} sm={0} md={0} lg={12}>
            <Image src="/image/login-cover.png" preview={false} />
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} className="px-3">
            <Row align="middle" justify="center" className="mb-5 mt-3">
              <Image
                src="/logo.svg"
                preview={false}
                style={{ maxWidth: 250 }}
              />
            </Row>
            <Row justify="center">
              <Card style={{ maxWidth: "600px" }}>
                <LoginForm router={router} />
              </Card>
            </Row>
            <Footer className="d-flex justify-content-center mt-5">
              &copy; 2021 Educa Corporation. All rights reserved
            </Footer>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}
