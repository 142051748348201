import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Checkbox, Form, Input, Modal } from "antd";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";
import authApi from "src/api/authApi";
import { useModalState } from "src/hooks/useModalState";
import { setUser } from "src/libs/browser";
import { URL_TEMPLATE } from "src/libs/constant";
import styled from "styled-components";

export default function () {
  const router = useRouter();

  return (
    <Container>
      <LoginBackgroundBottom>
        <img src="/image/login-background-bottom.png" />
      </LoginBackgroundBottom>
      <LogoBackgroud>
        <img src="/image/login-background-logo.png" />
      </LogoBackgroud>
      <Logo>
        <img src="/image/login-logo.png" />
      </Logo>
      <SnowerIcon>
        <img src="/image/login-snower.png" />
      </SnowerIcon>
      <LoginContainer>
        <LoginRingIcon>
          <img src="/image/ring-icon.png" />
        </LoginRingIcon>
        <LoginMerryChistmas>
          <img src="/image/login-merry-christmas.png" />
        </LoginMerryChistmas>

        <LoginForm router={router} />
      </LoginContainer>
      <ChickenIcon>
        <img src="/image/login-chicken.png" />
      </ChickenIcon>
    </Container>
  );
}
const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("/image/login-background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
`;

const LogoBackgroud = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  @media only screen and (max-width: 992px) {
    img {
      width: 70%;
    }
  }
  @media only screen and (max-width: 600px) {
    img {
      width: 95%;
    }
  }
`;

const LoginBackgroundBottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  object-fit: cover;
  height: auto;
  img {
    object-fit: cover;
    width: 100%;
  }
`;

const Logo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  @media only screen and (max-width: 992px) {
    img {
      width: 60%;
    }
  }
`;

const ChickenIcon = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  /* On screens that are 992px wide or less*/
  @media only screen and (max-width: 992px) {
    align-items: end;
    justify-content: end;
    display: flex;
    img {
      width: 50%;
    }
  }
`;
const SnowerIcon = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  @media only screen and (max-width: 992px) {
    img {
      width: 50%;
    }
  }
`;

const LoginContainer = styled.div`
  /* background: linear-gradient(111.85deg, #0A60AF -6.53%, rgba(255, 255, 255, 0) 100%), */
  /* linear-gradient(0deg, #FFFFFF, #FFFFFF); */
  padding: 24px 70px;
  border-radius: 29px;
  border: 3px solid rgba(255, 255, 255, 1);
  position: relative;
  background-image: url("/image/background-login-form.png");
  background-repeat: no-repeat;
  background-size: cover;
  @media only screen and (max-width: 992px) {
    padding: 24px 60px;
  }
  @media only screen and (max-width: 600px) {
    padding: 24px 40px;
  }
`;
const LoginMerryChistmas = styled.div`
  margin: 10px 20px 40px 20px;
`;

const LoginRingIcon = styled.div`
  position: absolute;
  top: -20px;
  right: -30px;
`;

function LoginForm({ router }) {
  const { t } = useTranslation();
  const [isSubmitting, setSubmitting] = useState(false);
  const { handleClose, handleOpen, isModalVisible } = useModalState();
  const inputRef = useRef(null);
  const [form] = Form.useForm();
  useEffect(() => {
    inputRef.current?.focus({
      cursor: "end",
    });
  }, []);
  const onFinish = async (values) => {
    const { keepLogin, username: userName, password } = values;
    setSubmitting(true);
    const { status, data } = await authApi.login({ userName, password });
    if (status !== 200) {
      setSubmitting(false);
      if (status === 400) {
        form.setFields([
          {
            name: "userName",
            message: t("page.login.incorrect_password_or_username"),
          },
        ]);
        return;
      }
      handleOpen();
      return;
    }
    setUser(data, keepLogin);
    setTimeout(() => {
      // set cookie too slow
      let targetUrl = URL_TEMPLATE.SYSTEM_DASHBOARD;
      const { from } = router.query;
      if (from) {
        targetUrl = from;
      }
      window.open(targetUrl, "_self");
    }, 500);
  };

  return (
    <>
      <Form
        name="login-form"
        form={form}
        initialValues={{}}
        onFinish={onFinish}
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
      >
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: t("page.login.text.user_name_is_required"),
            },
          ]}
          // label={t('page.login.label.user_name')}
        >
          <InputStyle>
            <Input
              ref={inputRef}
              size="large"
              placeholder={t("page.login.label.user_name")}
            />
          </InputStyle>
        </Form.Item>

        <InputStyle>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: t("page.login.text.password_is_required"),
              },
            ]}
            // label={t('page.login.label.password')
          >
            <Input.Password
              size="large"
              placeholder={t("page.login.label.password")}
              iconRender={(visible) =>
                visible ? (
                  <EyeOutlined style={{ color: "#fff" }} />
                ) : (
                  <EyeInvisibleOutlined style={{ color: "#fff" }} />
                )
              }
              style={{
                borderRadius: "15px",
                border: "3px solid rgba(255, 255, 255, 1)",
                background: "transparent !important",
              }}
            />
          </Form.Item>
        </InputStyle>

        <Form.Item name="keepLogin" valuePropName="checked">
          <CheckboxStyle>
            <Checkbox>{t("page.login.text.keep_login")}</Checkbox>
          </CheckboxStyle>
        </Form.Item>
        <Form.Item>
          <ButtonStyle>
            <button
              size="large"
              htmlType="submit"
              type="primary"
              loading={isSubmitting}
            >
              {t("common.label.login")}
            </button>
          </ButtonStyle>
        </Form.Item>
      </Form>
      <Modal
        visible={isModalVisible}
        title={t("page.login.text.login_to_system")}
        destroyOnClose
        footer={null}
        onCancel={handleClose}
      >
        {t("common.text.system_error")}
      </Modal>
    </>
  );
}

const InputStyle = styled.div`
  input {
    border-radius: 15px;
    background: transparent !important;
  }
  input::placeholder {
    color: #fff;
    opacity: 1; /* Firefox */
  }
  input[type] {
    background: transparent !important;
    color: white;
    border: 3px solid rgba(255, 255, 255, 1);
  }
  input[type="text"] {
    /* padding-inline-start: 10px !important; */
  }
  input[type="password"] {
    padding-inline-start: 3px !important;
  }
  & .ant-input-affix-wrapper {
    background: transparent !important;
  }
  & .ant-form-item-has-success {
    border-color: white !important;
  }
  & .ant-form-item-has-error .ant-input-affix-wrapper {
    border-color: #ff4d4f !important;
  }
  & .ant-input:focus {
    border-color: white !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }
  input[data-autocompleted] {
    background-color: transparent !important;
  }
`;

const CheckboxStyle = styled.div`
  span {
    color: white;
    border-radius: 100%;
  }
`;

const ButtonStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    background-image: linear-gradient(
      to right,
      #ff8008 0%,
      #ffc837 51%,
      #ff8008 100%
    );
    margin: 10px;
    padding: 10px 40px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #6b4e23;
    border: 3px solid #fff !important;
    border-radius: 29px !important;
    display: block;
    &:hover {
      background-position: right center; /* change the direction of the change here */
      color: #fff;
      text-decoration: none;
    }
  }
`;
