import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Checkbox, Form, Input, Modal } from "antd";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";
import authApi from "src/api/authApi";
import { useModalState } from "src/hooks/useModalState";
import { setUser } from "src/libs/browser";
import { URL_TEMPLATE } from "src/libs/constant";
import styled from "styled-components";

export default function () {
  const router = useRouter();

  return (
    <Container>
      <Logo>
        <img src="/image/new-year-logo.png" />
      </Logo>
      <NewYearLeft>
        <img src="/image/new-year-left.png" />
      </NewYearLeft>
      <NewYearFrame>
        <img src="/image/new-year-frame.png" />
      </NewYearFrame>
      <NewYearLoginFrame>
        <img src="/image/new-year-login-frame.png" />
        <LoginContainer>
          <LoginForm router={router} />
        </LoginContainer>
      </NewYearLoginFrame>
      <NewYearRight>
        <img src="/image/new-year-right.png" />
      </NewYearRight>
    </Container>
  );
}
const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("/image/new-year-background.png");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
`;

const Logo = styled.div`
  position: absolute;
  top: 50px;
  left: 0;
  @media only screen and (max-width: 992px) {
    img {
      width: 80%;
    }
  }
`;

const NewYearFrame = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 992px) {
    img {
      width: 90%;
    }
  }
  @media screen and (max-width: 600px) {
    img {
      display: none;
    }
  }
`;
const NewYearLoginFrame = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
`;

const NewYearRight = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  /* On screens that are 992px wide or less*/
  @media only screen and (max-width: 992px) {
    align-items: end;
    justify-content: end;
    display: flex;
    img {
      width: 60%;
    }
  }
`;
const NewYearLeft = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  @media only screen and (max-width: 992px) {
    img {
      width: 60%;
    }
  }
`;

const LoginContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  top: 42%;
`;
function LoginForm({ router }) {
  const { t } = useTranslation();
  const [isSubmitting, setSubmitting] = useState(false);
  const { handleClose, handleOpen, isModalVisible } = useModalState();
  const inputRef = useRef(null);
  const [form] = Form.useForm();
  useEffect(() => {
    inputRef.current?.focus({
      cursor: "end",
    });
  }, []);
  const onFinish = async (values) => {
    const { keepLogin, username: userName, password } = values;
    setSubmitting(true);
    const { status, data } = await authApi.login({ userName, password });
    if (status !== 200) {
      setSubmitting(false);
      if (status === 400) {
        form.setFields([
          {
            name: "userName",
            message: t("page.login.incorrect_password_or_username"),
          },
        ]);
        return;
      }
      handleOpen();
      return;
    }
    setUser(data, keepLogin);
    setTimeout(() => {
      // set cookie too slow
      let targetUrl = URL_TEMPLATE.SYSTEM_DASHBOARD;
      const { from } = router.query;
      if (from) {
        targetUrl = from;
      }
      window.open(targetUrl, "_self");
    }, 500);
  };

  return (
    <ContainerLoginForm>
      <Form
        name="login-form"
        form={form}
        initialValues={{}}
        onFinish={onFinish}
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
      >
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: t("page.login.text.user_name_is_required"),
            },
          ]}
        >
          <InputStyle>
            <Input
              ref={inputRef}
              size="large"
              placeholder={t("page.login.label.user_name")}
            />
          </InputStyle>
        </Form.Item>

        <InputPasswordStyle>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: t("page.login.text.password_is_required"),
              },
            ]}
          >
            <Input.Password
              size="large"
              placeholder={t("page.login.label.password")}
              iconRender={(visible) =>
                visible ? (
                  <EyeOutlined style={{ color: "#d42529" }} />
                ) : (
                  <EyeInvisibleOutlined style={{ color: "#d42529" }} />
                )
              }
              style={{
                borderRadius: "15px",
                border: "3px solid rgba(255, 255, 255, 1)",
                background: "transparent !important",
              }}
            />
          </Form.Item>
        </InputPasswordStyle>

        <Form.Item name="keepLogin" valuePropName="checked">
          <CheckboxStyle>
            <Checkbox>{t("page.login.text.keep_login")}</Checkbox>
          </CheckboxStyle>
        </Form.Item>
        <Form.Item>
          <ButtonStyle>
            <button
              size="large"
              htmlType="submit"
              type="primary"
              loading={isSubmitting}
            >
              {t("common.label.login")}{" "}
              {isSubmitting && <i class="fa fa-spinner fa-spin"></i>}
            </button>
          </ButtonStyle>
        </Form.Item>
      </Form>
      <Modal
        visible={isModalVisible}
        title={t("page.login.text.login_to_system")}
        destroyOnClose
        footer={null}
        onCancel={handleClose}
      >
        {t("common.text.system_error")}
      </Modal>
    </ContainerLoginForm>
  );
}

const ContainerLoginForm = styled.div`
  .ant-form-item-explain-error {
    color: #fff !important;
  }
`;

const InputStyle = styled.div`
  input,
  input:hover,
  input:focus {
    border: double 3px transparent;
    border-radius: 15px;
    background-image: linear-gradient(white, white),
      linear-gradient(to right, #ff8008 0%, #ffc837 51%, #ff8008 100%);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    border-color: transparent !important;
    box-shadow: none !important;
  }
  .ant-input-focused {
    box-shadow: none !important;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s !important;
  }
  input[data-autocompleted] {
    background-color: transparent !important;
  }
  input:-internal-autofill-selected {
    background-image: none !important;
    background-color: transparent !important;
    color: transparent !important;
  }
`;

const InputPasswordStyle = styled.div`
  .ant-form-item-control-input-content {
    border: double 3px transparent;
    border-radius: 15px;
    background-image: linear-gradient(white, white),
      linear-gradient(to right, #ff8008 0%, #ffc837 51%, #ff8008 100%);
    background-origin: border-box;
    background-clip: padding-box, border-box;
    border-color: transparent !important;
    box-shadow: transparent !important;
  }
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    box-shadow: none !important;
  }
`;

const CheckboxStyle = styled.div`
  span {
    color: white;
    border-radius: 100%;
  }
  .ant-checkbox-inner {
    background-color: transparent !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-image: linear-gradient(
      to right,
      #ff8008 0%,
      #ffc837 51%,
      #ff8008 100%
    ) !important;
    border-color: linear-gradient(
      to right,
      #ff8008 0%,
      #ffc837 51%,
      #ff8008 100%
    ) !important;
    background-color: linear-gradient(
      to right,
      #ff8008 0%,
      #ffc837 51%,
      #ff8008 100%
    ) !important;
    &::after,
    &:hover {
      background-color: transparent !important;
      border-color: transparent !important;
    }
  }
`;

const ButtonStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    background-image: linear-gradient(
      to right,
      #ff8008 0%,
      #ffc837 51%,
      #ff8008 100%
    );
    /* margin: 10px; */
    padding: 10px 40px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #6b4e23;
    border: 3px solid #fff !important;
    border-radius: 29px !important;
    display: block;
    &:hover {
      background-position: right center; /* change the direction of the change here */
      color: #fff;
      text-decoration: none;
    }
  }
`;
