import { Form, Input, Checkbox, Button, Modal } from "antd";
import { useTranslation } from "next-i18next";
import { useEffect, useRef, useState } from "react";
import { useModalState } from "src/hooks/useModalState";
import { setUser } from "src/libs/browser";
import authApi from "src/api/authApi";
import { URL_TEMPLATE } from "src/libs/constant";

export default function LoginForm({ router }) {
  const { t } = useTranslation();
  const [isSubmitting, setSubmitting] = useState(false);
  const { handleClose, handleOpen, isModalVisible } = useModalState();
  const inputRef = useRef(null);
  const [form] = Form.useForm();
  useEffect(() => {
    inputRef.current?.focus({
      cursor: "end",
    });
  }, []);
  const onFinish = async (values) => {
    const { keepLogin, username: userName, password } = values;
    setSubmitting(true);
    const { status, data } = await authApi.login({ userName, password });
    if (status !== 200) {
      setSubmitting(false);
      if (status === 400) {
        form.setFields([
          {
            name: "userName",
            message: t("page.login.incorrect_password_or_username"),
          },
        ]);
        return;
      }
      handleOpen();
      return;
    }
    setUser(data, keepLogin);
    setTimeout(() => {
      // set cookie too slow
      let targetUrl = URL_TEMPLATE.SYSTEM_DASHBOARD;
      const { from } = router.query;
      if (from) {
        targetUrl = from;
      }
      window.open(targetUrl, "_self");
    }, 500);
  };

  return (
    <>
      <Form
        name="login-form"
        form={form}
        initialValues={{}}
        onFinish={onFinish}
        wrapperCol={{ span: 24 }}
        labelCol={{ span: 24 }}
      >
        <Form.Item
          name="username"
          rules={[
            {
              required: true,
              message: t("page.login.text.user_name_is_required"),
            },
          ]}
          label={t("page.login.label.user_name")}
        >
          <Input ref={inputRef} size="large" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: t("page.login.text.password_is_required"),
            },
          ]}
          label={t("page.login.label.password")}
        >
          <Input.Password size="large" />
        </Form.Item>
        <Form.Item name="keepLogin" valuePropName="checked">
          <Checkbox>{t("page.login.text.keep_login")}</Checkbox>
        </Form.Item>
        <Form.Item>
          <Button
            size="large"
            htmlType="submit"
            type="primary"
            loading={isSubmitting}
          >
            {t("common.label.login")}
          </Button>
        </Form.Item>
      </Form>
      <Modal
        visible={isModalVisible}
        title={t("page.login.text.login_to_system")}
        destroyOnClose
        footer={null}
        onCancel={handleClose}
      >
        {t("common.text.system_error")}
      </Modal>
    </>
  );
}
