import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'

import { isLoggedIn } from '../src/libs/browser'
import { URL_TEMPLATE } from '../src/libs/constant'
import LoginNomal from 'src/components/profile/LoginNomal'
import LoginChrismas from 'src/components/profile/LoginChrismas'
import LoginNewYear from 'src/components/profile/LoginNewYear'


const Login = () => {
  const router = useRouter()

  useEffect(() => {
    if (isLoggedIn()) {
      router.push(URL_TEMPLATE.SYSTEM_DASHBOARD)
    }
  }, [])

  return (
    <LoginNomal/>
    // <LoginChrismas/>
    // <LoginNewYear/>
  )
}

export const getStaticProps =
    async ({ locale }) => {
      return {
        props: {
          ...await serverSideTranslations(locale, ['common'])
        }
      }
    }

export default Login
